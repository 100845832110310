import React, { ChangeEvent, FormEvent } from 'react';
import { Typography, makeStyles, Grid, Button, InputLabel, Theme } from '@material-ui/core';
// import { useAppState } from '../../../state';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
    color: '#f1f0e2',
    fontFamily: `{"system-ui", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, "System Default", serif}`,
    fontWidth: '900',
  },
  mainText: {
    color: '#f1f0e2',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
    color: '#f1f0e2',
  },
  continueButton: {
    backgroundColor: '#CCFFF5',
    color: '#1a1a1a',
    fontWeight: 900,
    fontStyle: 'italic',
  },
}));

interface RoomNameScreenProps {
  // name: string;
  roomName: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({ roomName, setName, setRoomName, handleSubmit }: RoomNameScreenProps) {
  const classes = useStyles();
  // const { user } = useAppState();

  // const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   setName(event.target.value);
  // };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const hasUsername = true;

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        LIIT
      </Typography>
      <Typography className={classes.mainText} variant="body1">
        {hasUsername
          ? "Enter the name of a room you'd like to join."
          : "Enter your name and the name of a room you'd like to join"}
      </Typography>
      <form onSubmit={handleSubmit}>
        <div className={classes.inputContainer}>
          {!hasUsername && (
            <div className={classes.textFieldContainer}>
              <InputLabel className={classes.textFieldContainer} shrink htmlFor="input-user-name">
                Your Name
              </InputLabel>
              {/* <TextField 
                id="input-user-name"
                variant="outlined"
                fullWidth
                size="small"
                placeholder="Name"
                value={name}
                onChange={handleNameChange}
              /> */}
            </div>
          )}
          <div className={classes.textFieldContainer}>
            <InputLabel className={classes.textFieldContainer} shrink htmlFor="input-room-name">
              Room Name
            </InputLabel>
            {/* <TextField
              autoCapitalize="false"
              id="input-room-name"
              variant="outlined"
              fullWidth
              size="small"
              value={roomName}
              onChange={handleRoomNameChange}
            /> */}
            {/* <select onChange={handleRoomNameChange} value={roomName}>
              <option value="Operations">Operations</option>
              <option value="Sales">Sales</option>
            </select> */}

            <div onChange={handleRoomNameChange}>
              <input type="radio" name="Facility" />
              <label htmlFor="Operations">Operations</label>
              <input type="radio" name="Facility" />
              <label htmlFor="Sales">Sales</label>
            </div>
          </div>
        </div>
        <Grid container justify="flex-end">
          <Button variant="contained" type="submit" disabled={!roomName} className={classes.continueButton}>
            VIDEO-TELEPHONE
          </Button>
        </Grid>
      </form>
    </>
  );
}
